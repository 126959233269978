import request from "@/utils/request"

// 商机一览查询
export function serverApiListPage(data) {
  return request({
    url: "/businessOpportunity/listPage",
    method: "post",
    data
  })
}

// 线索-专家 修改跟进状态 /clueSpecialist/updateClueSpecialistStatus
export function updateClueSpecialistStatus(data) {
  return request({
    url: "/clueSpecialist/updateClueSpecialistStatus",
    method: "post",
    data
  })
}

// 商机-线索日志列表 /businessOpportunity/selectClueLogPage
export function selectClueLogPage(data) {
  return request({
    url: "/businessOpportunity/selectClueLogPage",
    method: "post",
    data
  })
}